define("discourse/plugins/discourse-teambuild/discourse/templates/team-build", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="team-build">
    <h1>{{this.siteSettings.teambuild_name}}</h1>
  
    <ul class="nav nav-pills">
      <NavItem
        @route="teamBuild.index"
        @label="discourse_teambuild.scores.title"
      />
      <NavItem
        @route="teamBuild.progress"
        @label="discourse_teambuild.progress.title"
      />
      {{#if this.currentUser.staff}}
        <NavItem
          @route="teamBuild.manage"
          @label="discourse_teambuild.manage.title"
          @icon="wrench"
        />
      {{/if}}
    </ul>
  
    {{outlet}}
  </div>
  */
  {
    "id": "S+nSQ0xI",
    "block": "[[[10,0],[14,0,\"team-build\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[30,0,[\"siteSettings\",\"teambuild_name\"]]],[13],[1,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@route\",\"@label\"],[\"teamBuild.index\",\"discourse_teambuild.scores.title\"]],null],[1,\"\\n    \"],[8,[39,3],null,[[\"@route\",\"@label\"],[\"teamBuild.progress\",\"discourse_teambuild.progress.title\"]],null],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"staff\"]],[[[1,\"      \"],[8,[39,3],null,[[\"@route\",\"@label\",\"@icon\"],[\"teamBuild.manage\",\"discourse_teambuild.manage.title\",\"wrench\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"div\",\"h1\",\"ul\",\"nav-item\",\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-teambuild/discourse/templates/team-build.hbs",
    "isStrictMode": false
  });
});