define("discourse/plugins/discourse-teambuild/discourse/templates/team-build-progress", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.teambuild_description}}
    <div class="teambuild-description">
      {{this.siteSettings.teambuild_description}}
    </div>
  {{/if}}
  
  <div class="completed-score">
    <div class="username">@{{this.progress.user.username}}</div>
    <div class="status">
      {{i18n "discourse_teambuild.progress.completed"}}:
      {{this.progress.completed.length}}
      /
      {{this.progress.total}}
    </div>
  </div>
  
  <div class="all-targets">
    {{#each this.progress.teambuild_targets as |target|}}
      <div class="target-type">
        {{#if target.group_id}}
          <div class="description">{{replace-emoji target.name}}</div>
          <div class="target-choices multi-choice">
            {{#each target.users as |u|}}
              <TeambuildChoice
                @label={{u.username}}
                @progress={{this.progress}}
                @target={{target}}
                @userId={{u.id}}
                @readOnly={{this.readOnly}}
              />
            {{/each}}
          </div>
        {{else}}
          <TeambuildChoice
            @label={{replace-emoji target.name}}
            @progress={{this.progress}}
            @target={{target}}
            @userId={{this.progress.user.id}}
            @readOnly={{this.readOnly}}
          />
        {{/if}}
      </div>
    {{else}}
      <p>{{i18n "discourse_teambuild.progress.none"}}</p>
    {{/each}}
  </div>
  */
  {
    "id": "jE/RxiEl",
    "block": "[[[41,[30,0,[\"siteSettings\",\"teambuild_description\"]],[[[1,\"  \"],[10,0],[14,0,\"teambuild-description\"],[12],[1,\"\\n    \"],[1,[30,0,[\"siteSettings\",\"teambuild_description\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"completed-score\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"username\"],[12],[1,\"@\"],[1,[30,0,[\"progress\",\"user\",\"username\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"status\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"discourse_teambuild.progress.completed\"],null]],[1,\":\\n    \"],[1,[30,0,[\"progress\",\"completed\",\"length\"]]],[1,\"\\n    /\\n    \"],[1,[30,0,[\"progress\",\"total\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"all-targets\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"progress\",\"teambuild_targets\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"target-type\"],[12],[1,\"\\n\"],[41,[30,1,[\"group_id\"]],[[[1,\"        \"],[10,0],[14,0,\"description\"],[12],[1,[28,[35,5],[[30,1,[\"name\"]]],null]],[13],[1,\"\\n        \"],[10,0],[14,0,\"target-choices multi-choice\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"users\"]]],null]],null],null,[[[1,\"            \"],[8,[39,6],null,[[\"@label\",\"@progress\",\"@target\",\"@userId\",\"@readOnly\"],[[30,2,[\"username\"]],[30,0,[\"progress\"]],[30,1],[30,2,[\"id\"]],[30,0,[\"readOnly\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,6],null,[[\"@label\",\"@progress\",\"@target\",\"@userId\",\"@readOnly\"],[[28,[37,5],[[30,1,[\"name\"]]],null],[30,0,[\"progress\"]],[30,1],[30,0,[\"progress\",\"user\",\"id\"]],[30,0,[\"readOnly\"]]]],null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[1]],[[[1,\"    \"],[10,2],[12],[1,[28,[35,2],[\"discourse_teambuild.progress.none\"],null]],[13],[1,\"\\n\"]],[]]],[13]],[\"target\",\"u\"],false,[\"if\",\"div\",\"i18n\",\"each\",\"-track-array\",\"replace-emoji\",\"teambuild-choice\",\"p\"]]",
    "moduleName": "discourse/plugins/discourse-teambuild/discourse/templates/team-build-progress.hbs",
    "isStrictMode": false
  });
});